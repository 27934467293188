import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getrepeatsales({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.repeatsalesdata().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async getrepeatsalesIgnore({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.Ignorerepeatsalesdata().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignorerepeatsales({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.repeatsalesIgnore(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignorerepeatsalesBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.repeatsalesignorebulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },


  // 
  async getrepeatpurchase({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.repeatpurchasedata().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async getrepeatpurchaseIgnore({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.Ignorerepeatpurchasedata().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignorerepeatpurchase({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.repeatpurchaseIgnore(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignorerepeatpurchaseBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.repeatpurchaseignorebulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
