import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getsupplierData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerosupplierdata().then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  async supplierIgnoredata({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xerosupplierIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
           // const data = response;
           // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },




  async ignoresupplier({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.supplierIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreSupplierBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.supplierignorebulk(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
