import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getunreconciledData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerounreconcileddata().then(
        (response) => {
          if (response.status == 200) { 
            const data = response.data;
            commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async unreconciledignoreData({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.xerounreignoredata(data).then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  /*async ignoreunreconciled({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xerounreconIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },*/

  async ignoreunreconciled({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xerounreconIgnore(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },


  async ignoreunreconciledBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreunreconBulk(data).then(
        (response) => {
          if (response.status == 200) { 
            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
