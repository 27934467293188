import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getEtData({ commit }) { 
    return new Promise((resolve, reject) => {
      XeroService.xeroEtdata().then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },
  async getEttData({ commit }) { 
    return new Promise((resolve, reject) => {
      XeroService.xeroEttdata().then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },
async getStaffData({ commit }) { 
    return new Promise((resolve, reject) => {
      XeroService.getStaffData().then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },
async getClientData({ commit }) { 
    return new Promise((resolve, reject) => {
      XeroService.getClientData().then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },
  async xeroEtIgnore({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.etIgnoredata().then(
        (response) => {
          if (response.status == 200) { 
            const data = response;
            commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreEt({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.etIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreEtBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreEtBulk(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },






};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
