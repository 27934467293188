import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {

  async getmjwithvat_action(data) {  
    return new Promise((resolve, reject) => {
      XeroService.get_mj_api(data).then((response) => {
        // console.log(response);
          if (response.status == 200) {
           
            resolve(response);
          } else {          
            reject(response);
          }
        }
      );
    })
  },

  async ignore_mj_action({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.mj_ignore_api(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async getdata_mj_ignore_action({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.get_mj_ignore_data_api(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async ignore_mj_bulk_action({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.mj_ignore_bulk_api(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};


export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
