import axios from 'axios';
import { setItem, getItem, removeItem } from '../utility/localStorageControl';


const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
localStorage.setItem("search_data",JSON.stringify([]));

const authHeader = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
});

class DynamicService {
    
    getsupplierlist() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        getItem
        return axios
            .post(API_ENDPOINT + 'api/auth/getsupplierlist', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    removeItem('user');
                    removeItem('access_token');
                    return response;
                }
            });
    }

    getsupplierInvoice(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('contact_id',  api_data['contact_id']);
        data.append('contact_status',  api_data['contact_status']);
        console.log(data);
        getItem
        return axios
            .post(API_ENDPOINT + 'api/auth/getsupplierInvoice', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }

    

    

    

    ignoreSupplierInvoice(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.id);
        data.append('option', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreSupplierInvoice', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    ignoreSupplierInvoicebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreSupplierInvoice', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    getvalueInvoice(api_data){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('amt',  api_data['amt']);
        data.append('amt_type',  api_data['amt_type']);
        data.append('inv_type',  api_data['inv_type']);
        data.append('value_status',  api_data['contact_status']);
        console.log(data);
        return axios
            .post(API_ENDPOINT + 'api/auth/getvalueInvoice', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }


    ignoreValueInvoice(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.id);
        data.append('option', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreValueInvoice', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    ignoreValueInvoicebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreValueInvoice', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    
}

export default new DynamicService();
