import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getdebtorData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerodebtordata().then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async debtorIgnoredata({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xerodebtorIgnore(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoredebtor({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.debtorIgnore(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async ignoredebtorBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.debtorignorebulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
