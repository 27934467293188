import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getinvoiceData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xeroinvoicesdata().then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  async xeroinvoiceIgnore({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xeroinvoiceIgnore().then(
        (response) => {
          if (response.status == 200) { 
            const data = response;
            commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  // async ignoreInvoice({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     XeroService.invoiceIgnore(data).then(
  //       (response) => {
  //         if (response.status == 200) { 
  //           // const data = response;
  //           // // commit('readSuccess', data);
  //           resolve(response)
  //         } else {
  //           // commit('logoutErr', response);
  //           reject(response)
  //           // reject('Wrong email id or password ')
  //         }
  //       }
  //     );
  //   })
  // },

 async ignoreInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.invoiceIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreInvoiceBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.invoiceignorebulk(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
