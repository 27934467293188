import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({
  data: '',
  loading: false,
  error: null,
});

const actions = {
  async getPIDraftData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xeroPIDraftData().then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async getPIignoreData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerogetPIignore().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async xeroPIDraftIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.PIDraftIgnore(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async ignorePIDraftBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.PIignorebulk(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async getSIDraftData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xeroSIDraftData().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async getSIDraftIgnore({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.xerogetSIignore(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async ignoreSIDraft({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.SIDraftIgnore(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
  async ignoreSIDraftBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.SIignorebulk(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
