import mutations from './mutations';
import DynamicService from '../../../../services/dynamic.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});
 

const actions = {
  async userGetData({ commit }, data) {
    try {
      commit('readBegin');
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },
  
  async getsupplierlist({ commit }, data) {
    return new Promise((resolve, reject) => {
        DynamicService.getsupplierlist(data).then(
        (response) => {
          if (response.status == 200) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);

            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreSupplierInvoicebulk({ commit }, data) {
    return new Promise((resolve, reject) => {
        DynamicService.ignoreSupplierInvoicebulk(data).then(
        (response) => {
          if (response.status == 200) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);

            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async getsupplierInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
        DynamicService.getsupplierInvoice(data).then(
        (response) => {
          if (response.status == 200) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);

            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreSupplierInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
        DynamicService.ignoreSupplierInvoice(data).then(
        (response) => {
          if (response.status == 200) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);

            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },
  

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
