import mutations from './mutations';
import RoleService from '../../../services/role.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});
 

const actions = {
  async roleGetData({ commit }, data) {
    try {
      commit('readBegin');
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },

  async adminData({ commit }, data) {
    return new Promise((resolve, reject) => {
      RoleService.adminData(data).then(
        (response) => {
          if (response.status == 200) {
            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },


  async  getPermissionData({ commit }) {
    return new Promise((resolve, reject) => {
      RoleService.getpermission().then(
        (response) => {
          if (response.status == 200) {
            resolve(response)
          } else {
            reject(response)
          }
        }
      );
    })
  },

  async rolemasterSearchData({ commit }, roledata) {
    try {
      commit('readBegin');
      const data = roledata.userdata.filter(item => item.role_name.toUpperCase().startsWith(roledata.serchtext.toUpperCase()));
      commit('readSuccess', data);
    } catch (err) {
      commit('readErr', err);
    }
  },


  async roleAddData({ commit }, data) {
    return new Promise((resolve, reject) => {
      RoleService.roleAdd(data).then(
        (response) => {
          if (response.status < 202) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async adminUpdateData({ commit }, data) {
    return new Promise((resolve, reject) => {
      RoleService.adminEdit(data).then(
        (response) => {
          if (response.status < 202) {
            // commit('logoutBegin');
            // Cookies.remove('logedIn');
            // commit('logoutSuccess', null);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  async adminDeleteData({ commit }, data) {
    try {
      return new Promise((resolve, reject) => {
        RoleService.adminDelete(data.company_id).then(
          (response) => {
            commit('readBegin');
            commit('readSuccess', data.data);
          }
        );
      })
      
    } catch (err) {
      commit('readErr', err);
    }
  },

  
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
