import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getincorrectvatData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xeroincorrectvatdata(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async xeroincorrectvatIgnore({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.incorrectvatIgnoredata(data).then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreincorrectvat({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.incorrectvatIgnore(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async incorrectvatignoreBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.incorrectvatBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
