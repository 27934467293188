import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({
  data: '',
  loading: false,
  error: null,
});

const actions = {
  async getjournalData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerojournaldata().then((response) => {
        if (response.status == 200) {
          //const data = response;
         // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async getjournalDataIgnore({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.journalIgnoredata().then((response) => {      
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreJournal({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.journalIgnore(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreJournalBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignorejournalBulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
