import axios from 'axios';
import { setItem, getItem, removeItem } from '../utility/localStorageControl';


const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const authHeader = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
});

class UserService {
    // checkuser(user) {
    //     var data = new FormData();
    //     data.append('email', user.email);
    //     data.append('password', user.password);
    //     return axios
    //         .post(API_ENDPOINT + 'api/auth/login', data)
    //         .then((response) => {
    //             if (response.status == 200) {
    //                 setItem('access_token', JSON.stringify(response.data.access_token));
    //                 setItem('user', JSON.stringify(response.data.user));

    //                 return response;
    //             } else {
    //                 return response;
    //             }
    //         });
    // }

    // logout() {
    //     var data = new FormData();
    //     return axios
    //         .post(API_ENDPOINT + 'api/auth/logout', data, {
    //             headers: { ...authHeader() }
    //         })
    //         .then(response => {
    //             if (response.status == 200) {
    //                 // alert('logout');
    //                 removeItem('user');
    //                 removeItem('access_token');
    //                 return response;
    //             } else {
    //                 return response;
    //             }
    //         });
    // }

    // register(user) {
    //     return axios.post(API_ENDPOINT + 'register', {
    //         username: user.username,
    //         email: user.email,
    //         password: user.password
    //     });
    // }

    // userData() {
    //     var data = new FormData();
    //     return axios
    //         .get(API_ENDPOINT + 'api/auth/user-profile', {
    //             headers: { ...authHeader() }
    //         })
    //         .then(response => {
    //             if (response.status == 200) {
    //                 console.log(response);
    //                 return response;
    //             } else {
    //                 removeItem('user');
    //                 removeItem('access_token');
    //                 return response;
    //             }
    //         });
    // }

    // userMaster

    getuserslist() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        getItem
        return axios
            .post(API_ENDPOINT + 'api/auth/getusers', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    removeItem('user');
                    removeItem('access_token');
                    return response;
                }
            });
    }

    getrolelist() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        getItem
        return axios
            .post(API_ENDPOINT + 'api/auth/getdropdata', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else {
                    return response;
                }
            });
    }

    userUpdate(userdata) {
        // console.log(userdata);
        var data = new FormData();
        data.append('user_id', userdata.id);
        data.append('f_name', userdata.f_name);
        data.append('l_name', userdata.l_name);
        data.append('email', userdata.email);
        data.append('role_id', userdata.designation);
        switch (userdata.status) {
            case 'active':
                data.append('status', 'A');
                break;
            case 'blocked':
                data.append('status', 'B');
                break;
            case 'deactivate':
                data.append('status', 'D');
                break; 
            default:
                break;
        }

        return axios
            .post(API_ENDPOINT + 'api/auth/edituser', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else { 
                    return response;
                }
            });
    }

    userAdd(userdata) {
        // console.log(userdata); 
        var data = new FormData();
        data.append('f_name', userdata.f_name);
        data.append('l_name', userdata.l_name);
        data.append('email', userdata.email);
        data.append('company_id', userdata.company_id);
        data.append('role_id', userdata.designation);
        data.append('password', userdata.password);
        data.append('status', 'D');

        return axios
            .post(API_ENDPOINT + 'api/auth/adduser', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else { 
                    return response;
                }
            });
    }

    userDelete(userdata) {
        // console.log(userdata); 
        var data = new FormData();
        data.append('user_id', userdata);
        return axios
            .post(API_ENDPOINT + 'api/auth/deleteuser', data, {
                headers: { ...authHeader() }
            })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response);
                    return response;
                } else { 
                    return response;
                }
            });
    }
}

export default new UserService();
