import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getwrongmismatchData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerowrongmismatchdata().then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async wrongmismatchIgnoredata({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerowrongmismatchIgnore().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async ignorewrongmismatchpay({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.wrongmismatchIgnorepay(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async wrongmismatchignoreBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignorewrongmismatchBulk(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
