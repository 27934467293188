import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getunuseddata({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerounuseddata().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async getunusedIgnore({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerounusedIgnore().then((response) => {
        if (response.status == 200) {
          const data = response;
          commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreunused({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.unsuedIgnore(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignoreunusedBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.unusedignorebulk(data).then((response) => {
        if (response.status == 200) {
          // const data = response;
          // commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
