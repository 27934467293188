import mutations from "./mutations";
import XeroService from "../../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getcapitalinvoiceData({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.xerocapitalinvoicedata().then((response) => {
        if (response.status == 200) {
          const data = response.data;
          commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async capitalinvoiceignoreData({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xerocapitalinvoiceignoredata(data).then((response) => {
        if (response.status == 200) {
          //const data = response;
          //commit('readSuccess', data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async ignorecapitalinvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.xerocapitalinvoiceIgnore(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },

  async ignorecapitalinvoiceBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignorecapitalinvoiceBulk(data).then((response) => {
        if (response.status == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
