import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getHmrcData({ commit }, data) { 
    return new Promise((resolve, reject) => {
      XeroService.getHmrcData(data).then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  async xeroHmrcIgnore({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.xeroHmrcIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response;
            commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreHmrc({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreHmrc(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreHmrcBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreHmrcBulk(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },






};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
