import mutations from "./mutations";
import XeroService from "../../../services/xero.service";

const state = () => ({
  data: "",
  loading: false,
  error: null,
});

const actions = {
  async getclientlist({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.getorgdata().then((response) => {
        if (response.status == 200) {
          // const data = response;
          // commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async getsyncdata({ commit }) {
    return new Promise((resolve, reject) => {
      XeroService.getsyncdata().then((response) => {
        if (response.status == 200) {
          // const data = response;
          // commit("readSuccess", data);
          resolve(response);
        } else {
          // commit('logoutErr', response);
          reject(response);
          // reject('Wrong email id or password ')
        }
      });
    });
  },

  async OrgGetData({ commit }) {
    try {
      commit("readBegin");
      commit("readSuccess");
    } catch (err) {
      commit("readErr", err);
    }
  },

  async contactAddData({ commit }, data) {
    try {
      commit("readBegin");
      commit("readSuccess", data);
    } catch (err) {
      commit("readErr", err);
    }
  },
  async contactDeleteData({ commit }, data) {
    try {
      commit("readBegin");
      commit("readSuccess", data);
    } catch (err) {
      commit("readErr", err);
    }
  },

  async onStarUpdate({ commit }, {id,tenent_id}) {
    try {

      return new Promise((resolve, reject) => {
        XeroService.xerofav(id,tenent_id).then(
          (response) => {
            if (response.status == 200) { 
              // const data = response;
              // commit('readSuccess', data);
              resolve(response)
            } else { 
              reject(response) 
            }
          }
        );
      }
      // commit("starUpdateBegin");
      // console.log(typeof data);
      // data.map((item) => {
      //   if (item.id === id) {
      //     const fav = item;
      //     if (item.stared) {
      //       fav.stared = false;
      //     } else {
      //       fav.stared = true;
      //     }
      //   }  
      //   console.log(data);
      //   return commit("starUpdateSuccess", data);
      // }
      );
    } catch (err) {
      commit("starUpdateErr", err);
    }
  },

  // async clientdatasort({ commit }, value) {
  //   try {
  //     commit("readBegin");
  //     const data = initialState.filter((item) =>
  //       item.name.toUpperCase().startsWith(value.toUpperCase())
  //     );
  //     commit("readSuccess", data);
  //   } catch (err) {
  //     commit("readErr", err);
  //   }
  // },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
