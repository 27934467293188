import axios from 'axios';
import { setItem, getItem, removeItem } from '../utility/localStorageControl';

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
localStorage.setItem("search_data",JSON.stringify([]));

const authHeader = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
});

class XeroService {
    xerosave(tenent_id) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', tenent_id.toString());
        return axios
            .post(API_ENDPOINT + 'api/auth/save_tenant_id', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerofav(id,tenent_id) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('id',id);
        data.append('tenent_id',tenent_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/addfav', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) { 
                    return response;
                } else {
                    return response;
                }
            });
    }

    getorgdata() {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    setItem('org', JSON.stringify(response.data.currentorg));
                    return response;
                } else {
                    return response;
                }
            });
    }

    
    getsyncdata() {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        return axios
            .post(API_ENDPOINT + 'api/auth/getsyncdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    setItem('org', JSON.stringify(response.data.currentorg));
                    return response;
                } else {
                    return response;
                }
            });
    }

    changeorg(tenent_id) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', tenent_id.toString());
        return axios
            .post(API_ENDPOINT + 'api/auth/changeorg', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerosync(daterange) { 
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id',user.email);
        data.append('company_id', user.company_id);
        data.append('startdate', daterange[0]);
        data.append('enddate',  daterange[1]);
        data.append('tenent_id',  localStorage.getItem('ten_id'));
        return axios
            .post(API_ENDPOINT + 'api/auth/syncDate', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    xeroreposync(daterange) { 
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('startdate', daterange[0]);
        data.append('enddate',  daterange[1]);
        data.append('tenent_id',  localStorage.getItem('ten_id'));
        return axios
            .post(API_ENDPOINT + 'api/auth/syncdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    //hamid

    xeroResync(tenent_id) { 
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', tenent_id);
        //data.append('tenent_id', user.tenent_id);

       
        return axios
            .post(API_ENDPOINT + 'api/auth/resyncdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    xerodisconnect(tenent_id){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  tenent_id.toString());
        
        return axios
            .post(API_ENDPOINT + 'api/auth/disconnect', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    xerorefresh(tenent_id){
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  tenent_id.toString());
        
        return axios
            .post(API_ENDPOINT + 'api/auth/refresh_token', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! contact api
    xerocontactdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getcontactdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerocontactIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/contactIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    contactignore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreContact', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    contactignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreContactBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! invoice api
    xeroinvoicesdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getinvoicedata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroinvoiceIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/invoiceIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // invoiceIgnore(ignoredata) {
    //     var user = JSON.parse(localStorage.getItem('user'));
    //     var data = new FormData();
    //     data.append('user_id', user.id);
    //     data.append('company_id', user.company_id);
    //     data.append('record_id', ignoredata.id);
    //     data.append('status', ignoredata.status);
    //     return axios
    //         .post(API_ENDPOINT + 'api/auth/ignoreInvoice', data, {
    //             headers: { ...authHeader() }
    //         })
    //         .then((response) => {
    //             if (response.status == 200) {
    //                 return response;
    //             } else {
    //                 return response;
    //             }
    //         });
    // }

  invoiceIgnore(ignoredata) {
    var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.id);
        data.append('option', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreInvoice', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
   }
    invoiceignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreInvoiceBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! nominal api
    xeronominaldata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getnominaldata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeronominalignore(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/nominalIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    nominalIgnore(record_id) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', record_id.id);
        data.append('status', record_id.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreNominal', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreNominalBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreNominalBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! vat api
    xerovatdata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getvatdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    vatIgnoredata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/vatIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    vatIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreVat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreVatBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreVatBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
// ! Sup api
    xerosupdata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getsupdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    supIgnoredata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/supIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    supIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreSup', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreSupBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreSupBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
// ! Foreign api
    getForeignData(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getForeigndata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroForeignIgnore(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/foreignIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreForeign(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreForiegn', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreForeignBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreForeignBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! HMRC api
    getHmrcData(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getHmrcData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroHmrcIgnore(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/xeroHmrcIgnore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreHmrc(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreHmrc', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreHmrcBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreHmrcBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Allocation api
    getAllocationsData(api_data) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('choice',  api_data['choice']);
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getAllocationsData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    XeroAllocationIgnore(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/XeroAllocationIgnore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreAllocation(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreAllocation', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    allocationIgnoreBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/allocationIgnoreBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Payment Modes api
    getPaymentsModesData(api_data) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('choice',  api_data['choice']);
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getPaymentsModesData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    XeroPaymentModeIgnore(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/XeroPaymentModeIgnore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignorePaymentModes(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorePaymentModes', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    paymentModesIgnoreBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/paymentModesIgnoreBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Description api
    getDescriptionData(api_data) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('choice',  api_data['choice']);
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getDescriptionData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    XeroDescriptionIgnore(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/XeroDescriptionIgnore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreDescription(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreDescription', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    descriptionIgnoreBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/descriptionIgnoreBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Fa Invoices api
    xeroFadata(name) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('value', localStorage.getItem('f_name'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getfadata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    faIgnoredata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/faIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    faIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreFa', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreFaBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreFaBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! VAT late claims api
    xeroVatlatedata(name) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('startdate', localStorage.getItem('start'));
        data.append('enddate', localStorage.getItem('end'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getVatlatedata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    vatlateIgnoredata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/vatlateIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    vatlateIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreVatlate', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreVatlateBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreVatlateBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Potential Prepayments api
    xeroPredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getpredata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    preIgnoredata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/preIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    preIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorePre', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignorePreBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorePreBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Director Invoices api
    xeroDadata(name) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        data.append('value', localStorage.getItem('f_name'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getdadata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    daIgnoredata(daterange) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/daIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    daIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreDa', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreDaBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreDaBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! Entertainment Expenses api
    xeroEtdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getetdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    xeroEttdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getettdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    getStaffData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getStaffData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    getClientData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getClientData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    etIgnoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/etIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    etIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreEt', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreEtBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreEtBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
// ! Accrual Report api
    xeroAccdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getAccData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    accIgnoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        
        return axios
            .post(API_ENDPOINT + 'api/auth/getignoreAccData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    accIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreAccrual', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreAccBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorrAccrualBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! Unallocated Pay api
    xeropaydata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getpaymentdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeropayIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/paymentIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    upayIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreupay', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreupayBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreupayBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! Unallocated receipt api
    xeroreceiptdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getreceiptdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroreceiptIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/receiptIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    receiptIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorereceipt', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreReceiptBulk(ignoredata) {
        // console.log(ignoredata);
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreureceiptBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // !  Draft
    xeroPIDraftData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getpidraft', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerogetPIignore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/piignoredata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    PIDraftIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/pidraftignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    PIignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/piignorbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    xeroSIDraftData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getsidraft', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerogetSIignore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/siignoredata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    SIDraftIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/sidraftignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    SIignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/siignorbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }



    //! reconciled

    xerounreconcileddata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getunreconciled', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });

    }

    xerounreignoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getunreconignored', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerounreconIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreunreconciled', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreunreconBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreunreconbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    //! debit data
    DebitData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getdebitdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    IgnoreDebitData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        // data.append('tenent_id', JSON.parse(localStorage.getItem('org')));
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/debitignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    IgnoreDebit(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        // data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('overview_status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoredebit',data,{
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    BulkIgnoreDebit(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoredebitbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
 //! Credit data
    CreditData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getcreditdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    IgnoreCreditData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        // data.append('tenent_id', JSON.parse(localStorage.getItem('org')));
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/creditignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    IgnoreCredit(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        // data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('overview_status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorecredit',data,{
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    BulkIgnoreCredit(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorecreditbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
      // ! partial pay api
      xeropartialpaydata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getpartialpaydata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeropartialpayignordata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/partialpayignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignorepartialpay(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorepartialpay', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignorepartialpaybulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorepartialpaybulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    // ! partial receipt api
    xerogetpartialreceiptdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getpartialreceiptdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeropartialreceiptignordata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/partialreceiptignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignorepartialreceipt(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorepartialreceipt', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignorepartialreceiptbulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorepartialreceiptbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! suplliersinvoice api
    xerosupplierdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getsupplierdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerosupplierIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/supplierignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    supplierIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoresupplier', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }



    supplierignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoresupplierbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! debtorsinvoice api
    xerodebtordata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getgetdebtordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerodebtorIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/debtorignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    debtorIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoredebtor', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    debtorignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoredebtorbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // payment expensed routes started
    getpaymentexpensedData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getexpenseddata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    expensedignordata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/expensedignordata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreexpensed(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreexpensed', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }



    ignoreexpensedbulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreexpensedbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // payment expensed routes ended



    

     // ! unused api
     xerounuseddata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getunuseddata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerounusedIgnore() 
    {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/unusedignoredata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    unsuedIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreunused', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    unusedignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreunsuedbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    // ! repeat sales invoice api
    repeatsalesdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getrepeatsales', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    Ignorerepeatsalesdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getrepeatsalesignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    repeatsalesIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorrepeatsales', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    repeatsalesignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorerepeatsalesbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    // ! repeat purchase invoice api
    repeatpurchasedata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getrepeatpurchase', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    Ignorerepeatpurchasedata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getrepeatpurchaseignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    repeatpurchaseIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorrepeatpurchase', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    repeatpurchaseignorebulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorerepeatpurchasebulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


     // ! incorrect vat api
     xeroincorrectvatdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getincorrectvat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    incorrectvatIgnoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getincorrectvatignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    incorrectvatIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreincorrectvat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    incorrectvatBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreincorrectvatbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
    // ! BS NoVat api
     getNoVatData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getNoVatData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    getNoVatIgnoreData() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getNoVatIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreNoVat(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreNoVat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreNoVatBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreNoVatBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

     // ! manual journal with  vat api

     get_mj_api() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getmanualjournalvatdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    get_mj_ignore_data_api() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getmanualjournalIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    } 


    mj_ignore_api(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreManualjournal', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    mj_ignore_bulk_api(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreManualjournalBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! late entries

    get_lateentry_api() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/getmanualjournalvatdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    get_late_entries_data_api() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getmanualjournalIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    } 


    late_entries_ignore_api(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreManualjournal', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

   late_entries_bulk_api(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreManualjournalBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }



    // ! nominalvat api
    xeronominalvatdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        // data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getnominalvatdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    nominalvatIgnoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        // data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/nominalvatIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

  

    nominalvatIgnore(record_id) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', record_id.id);
        data.append('status', record_id.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreNominalvat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignoreNominalvatBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreNominalvatBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }
 // ! Journal Schedule api
    xerojournaldata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        // data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/getjournaldata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    journalIgnoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org'));
        // data.append('daterange', JSON.stringify(daterange));
        return axios
            .post(API_ENDPOINT + 'api/auth/journalIgnoreData', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

  

    journalIgnore(record_id) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', record_id.id);
        data.append('status', record_id.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreJournal', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignorejournalBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoreJournalBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    // ! wrongmismatch api
    xerowrongmismatchdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getwrongmismatch', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerowrongmismatchIgnore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getwrongmismatchignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    wrongmismatchIgnorepay(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorewrongmismatch', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignorewrongmismatchBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorewrongmismatchbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    // ! vatmismatch api
    xerovatmismatchdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getvatmismatch', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerovatmismatchignore() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getvatmismatchignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    } 


    xeroignorevatmismatch(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorevatmismatch', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignorevatmismatchbulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorevatmismatchbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


     // ! capitalinvoice api
     xerocapitalinvoicedata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getcapitalinvoce', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerocapitalinvoiceignoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getcapitalinvoceignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    } 


    xerocapitalinvoiceIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorecapitalinvoce', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    ignorecapitalinvoiceBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignorecapitalinvocebulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    // ! xerowrongvaluesbsdata api
    xerowrongvaluesbsdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getwrongvaluesbs', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerowrongvaluesbsignoredata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getgetwrongvaluesbsignore', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    } 


    xerowrongvaluesbsIgnore(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoregetwrongvaluesbs', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignorewrongvaluesbsBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoregetwrongvaluesbsbulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }


    // ! xeromjwithvatdata api
    xeromjwithvatdata() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id', localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getmjwithvatdata', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xerogetIgnoremjwithvat() {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('company_id', user.company_id);
        data.append('tenent_id',  localStorage.getItem('org')); 
        return axios
            .post(API_ENDPOINT + 'api/auth/getIgnoremjwithvat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    } 


    xeroignoremjwithvat(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user'));
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_id', ignoredata.id);
        data.append('status', ignoredata.status); 
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoremjwithvat', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    xeroignoremjwithvatBulk(ignoredata) {
        var user = JSON.parse(localStorage.getItem('user')); 
        var data = new FormData();
        data.append('user_id', user.id);
        data.append('company_id', user.company_id);
        data.append('record_ids', ignoredata.selectedid);
        data.append('option', ignoredata.option);
        data.append('tenent_id',  localStorage.getItem('org'));
        return axios
            .post(API_ENDPOINT + 'api/auth/ignoremjwithvatBulk', data, {
                headers: { ...authHeader() }
            })
            .then((response) => {
                if (response.status == 200) {
                    return response;
                } else {
                    return response;
                }
            });
    }

    

}

export default new XeroService();
