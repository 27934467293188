import mutations from './mutations';
import XeroService from '../../../../services/xero.service';

const state = () => ({

  data: '',
  loading: false,
  error: null,
});

const actions = {

  async getDaData({ commit }, name) { 
    return new Promise((resolve, reject) => {
      XeroService.xeroDadata(name).then(
        (response) => {
          if (response.status == 200) { 
            //const data = response;
            //commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },


  async xeroDaIgnore({ commit },data) {
    return new Promise((resolve, reject) => {
      XeroService.daIgnoredata(data).then(
        (response) => {
          if (response.status == 200) { 
            const data = response;
            commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreDa({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.daIgnore(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },

  async ignoreDaBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      XeroService.ignoreDaBulk(data).then(
        (response) => {
          if (response.status == 200) { 
            // const data = response;
            // // commit('readSuccess', data);
            resolve(response)
          } else {
            // commit('logoutErr', response);
            reject(response)
            // reject('Wrong email id or password ')
          }
        }
      );
    })
  },






};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
