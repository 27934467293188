import axios from 'axios';
import { setItem, getItem, removeItem } from '../utility/localStorageControl';

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

class RoleService {
  getpermission() {
    return axios.get(API_ENDPOINT + 'api/auth/permission').then((response) => {
      if (response.status == 200) {
        return response;
      } else {
        return response;
      }
    });
  }
  getroledata(user) {
      user = JSON.parse(localStorage.getItem('user'));
    var data = new FormData();
    data.append('company_id', user.company_id);
    return axios
      .post(API_ENDPOINT + 'api/auth/rolelist', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });
  }

  roleEdit(roledata) {
    var data = new FormData();
    data.append('role_id', roledata.id);
    data.append('role_name', roledata.role_name);
    data.append('permission', roledata.permission);
    data.append('company_id', roledata.company_id);
    return axios
      .post(API_ENDPOINT + 'api/auth/edit_role', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });
  }

  roleDelete(id) {
    var data = new FormData();
    data.append('role_id', id);
    return axios
      .post(API_ENDPOINT + 'api/auth/delete_role', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });

  }

  roleAdd(roledata) {
    var data = new FormData();
    data.append('role_name', roledata.role_name);
    data.append('permission', roledata.permission);
    data.append('company_id', roledata.company_id);

    return axios
      .post(API_ENDPOINT + 'api/auth/create_role', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });
  }
adminData(user) {
      user = JSON.parse(localStorage.getItem('user'));
    var data = new FormData();
    data.append('company_id', user.company_id);
    return axios
      .post(API_ENDPOINT + 'api/auth/adminUserList', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });
  }
adminEdit(admindata) {
    var data = new FormData();
    data.append('id', admindata.id);
    data.append('company', admindata.company);
    data.append('connection', admindata.connection);
    data.append('status', admindata.status);
    return axios
      .post(API_ENDPOINT + 'api/auth/admin_edit', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });
  }
adminDelete(id) {
    var data = new FormData();
    data.append('company_id', id);
    return axios
      .post(API_ENDPOINT + 'api/auth/admin_delete', data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status == 200) {
          return response;
        } else {
          return response;
        }
      });

  }  
}

export default new RoleService();
